// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bl_kz";
export var caseStudyBackground__bgColor = "bl_kp";
export var caseStudyBackground__lineColor = "bl_kq";
export var caseStudyCta__bgColor = "bl_kG";
export var caseStudyHead__imageWrapper = "bl_kn";
export var caseStudyProjectsSection = "bl_kB";
export var caseStudyQuote__bgLight = "bl_kx";
export var caseStudyQuote__bgRing = "bl_kw";
export var caseStudyTechSection = "bl_kH";
export var caseStudyVideo = "bl_kD";
export var caseStudyVideo__ring = "bl_kF";
export var caseStudy__bgDark = "bl_km";
export var caseStudy__bgLight = "bl_kl";